/* eslint-disable no-param-reassign */
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

// const API_URL = 'http://localhost:7000/api'
const API_URL = 'https://e-cev-utility.qcdigitalhub.com/api'

const axiosUtilities = axios.create({
  baseURL: API_URL,
})

axiosUtilities.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.authStore.apptoken}`
  config.headers.withCredentials = true
  return config
})

export default axiosUtilities
